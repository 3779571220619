import React from "react";
import { graphql } from 'gatsby';

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";
// import NewsQuiltAll from "src/components/news-quilt-all.js";
import NewsQuiltPanelArchive from "src/components/news-quilt-panel-archive";

const fetch = require('isomorphic-fetch')

/**
 * Port Info News Archive landing page
 */
class NewsAndPress extends React.Component {
  constructor(props) {
    super(props);
    this.addMoreArticles = this.addMoreArticles.bind(this);
    this.articleQuery = this.articleQuery.bind(this);
  }

  state = {
    queryNumber: this.props.location.state ? (this.props.location.state.queryNumber ? this.props.location.state.queryNumber : 1) : 1,
    articles: [],
    empty: false,
    totalArticleCount: this.props.location.state ? (this.props.location.state.totalArticleCount ? this.props.location.state.totalArticleCount : 0) : 0,
    pageInfo: []
  }

  componentDidMount() {
    const pageStart = this.state.totalArticleCount ? Math.ceil(this.state.totalArticleCount / 15) : this.state.queryNumber;
    const currentDate = new Date();
    const sixMonthPeriod = new Date(currentDate.setDate(currentDate.getDate() - 180));
    const fetchArray = this.state.articles;
    //console.log(pageStart);

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?slug=news-archive`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ pageInfo: json });
        } else {
          console.log("Data did not load");
        }
      })

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/news-posts?per_page=15&page=${pageStart}`)
      .then(response => response.json())
      .then(json => {
        if (json.posts.length > 0) {
          json.posts.map((item) => {
            const articleDate = new Date(item.date);
            if (articleDate.getTime() < sixMonthPeriod.getTime()) {
              fetchArray.push(item);
            }

            return null;
          })
        }
        this.setState({
          queryNumber: pageStart,
          articles: fetchArray
        });
        if (json.posts.length < 1) {
          this.setState({ empty: true });
        }
        if (fetchArray.length === 0) {
          this.addMoreArticles();
        }

        return json;
      })
  }

  articleQuery() {
    const fetchArray = this.state.articles;
    const currentDate = new Date();
    const sixMonthPeriod = new Date(currentDate.setDate(currentDate.getDate() - 180));

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/news-posts?per_page=15&page=${this.state.queryNumber}`)
      .then(response => response.json())
      .then(json => {
        if (json.posts.length > 0) {
         // console.log(json);
          json.posts.map((item) => {
            const articleDate = new Date(item.date);
            if (articleDate.getTime() < sixMonthPeriod.getTime()) {
              fetchArray.push(item);
            }

            return null;
          })
        }
        this.setState({ articles: fetchArray });
        if (fetchArray.length === 0) {
          this.addMoreArticles();
        }
        if (json.posts.length < 15) {
          this.setState({ empty: true });
        }

        return json;
      })
  }


  addMoreArticles() {
    this.setState({ queryNumber: this.state.queryNumber + 1 }, () => {
      this.articleQuery();
    });
  }


  render() {
    const pageData = this.state.pageInfo[0];
    const panelQuiltStyle = "pattern-01";
    const pathPrefix = '/port-info/news-and-press';
    //console.log(this.state);
    //console.log(this.state.articles);

    // Determine if panel is odd or even
    const isEven = (n) => {
      return n % 2 === 0;
    }

    // Generate random default image # from 1–4
    const getRandomDefaultImg = () => {
      const num = Math.floor(Math.random() * 4) + 1;
      return num;
    }

    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero>
              <SEO title={pageData.title.rendered} description="" />
              {(pageData.parent === 0 && pageData.acf) && (
                <Hero type="short" headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} mobileHeroBackgroundUrl={pageData.acf.mobile_background} subheading={pageData.acf.subheading} />
              )}

              <Breadcrumb categories={pageData.categories} pageTitle={pageData.title.rendered} pageId={pageData.id} />
              <ul className="quilt quilt--pattern-01 full-bleed-maxed">
                {
                  /*
                    There are two quilt display patterns: pattern-01 and pattern-02. Each dictates the order of img and text panels. See _quilt.scss for more details.
                  */
                  this.state.articles.map((item, index) => {
                    const data = item;
                    let img = '';
                    let imgPathRandom = '';
                    let text = '';
                    let link = item.link.slice(36);

                    // Set image or text depending on quilt style and whether panel is even or odd
                    if ((panelQuiltStyle === 'pattern-01' && isEven(index)) || (panelQuiltStyle === 'pattern-02' && !isEven(index))) {

                      if (data.acf.quilt_image_square) {
                        img = data.acf.quilt_image_square;
                      } else {
                        imgPathRandom = getRandomDefaultImg();
                      }
                    } else {
                      text = data.acf.news_excerpt;
                    }

                    // Construct props to set on quilt panel
                    const panel = {
                      imgPath: img,
                      imgPathRandom: imgPathRandom,
                      text: text,
                      title: data.title.rendered.replace(/&#8217;/, "'"),
                      url: pathPrefix + data.link.slice(24),
                    }

                    return (
                      <NewsQuiltPanelArchive key={data.id} {...panel} />
                    )
                  })
                }
              </ul>
              {this.state.empty ? "" : <span className="view-all full-bleed-maxed view-all-archive" onClick={this.addMoreArticles}>View More</span>}
            </Layout>
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default NewsAndPress;

// export const pageQuery = graphql`
//   query($tagName: String = "dev_only_id_news_archive") {
//     wordpressPage(tagNames: { in: [$tagName] }) {
//       id
//       slug
//       title
//       wordpress_parent
//       categories {
//         name
//         slug
//       }
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;



// <NewsQuiltAll quiltPanelsCount="15" quiltStyle="pattern-02" />